<template>
  <div class="th-img-port-wrp">
    <div class="th-img-port-wrp---frame">
        <image-zoom
          v-if="enableZoomEffect"
          ref="image"
          class="zoom-custom-wrapper"
          img-class="zoom-custom-wrapper--image"
          :alt="propsAlt"
          :show-message="false"
          :regular="`data:image/png;base64,
         ${propsUrl}`">
        </image-zoom>
        <img
          v-else
          ref="image"
          :src="`data:image/png;base64,
         ${propsUrl}`"
          :alt="propsAlt"
          :data-photo="propsphotoId"
        />
    </div>
    <img
      v-if="showFullscreenButton"

      @click.stop="fullscreen"
      src="../../assets/fullscreen.png"
      class="th-img-port-wrp---fullscreen"
    />
    <slot />
  </div>
</template>

<script>

export default {
  name: 'imagePortrait',
  props: {
    enableZoomEffect: {
      type: Boolean,
      default: false,
    },
    propsphotoId: {
      type: Number,
      default: null,
    },
    propsAlt: {
      type: String,
      default: 'default alt',
    },
    propsUrl: {
      type: String,
      default: 'https://i.imgur.com/jiV7Nod.jpg',
    },
    showFullscreenButton: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    fullscreen() {
      this.$emit('fullScreenImageEmit');
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-img-port-wrp {
    height: 206px;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include border-radius(10px);

    &---frame {
      height: 100%;
      width: 100%;
      background-color: $leonardo;
      text-align: center;

      & > img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    &---fullscreen {
      width: 24px;
      height: 24px;
      @include calculatePosition(absolute, 93%, 98%, -100%, -100%);
    }
  }
</style>
