<template>
  <div
    :class="setClass == 0 ? 'th-gf-wrp' : setClass == 1
    ? 'th-ls-wrp' : setClass == 2 ?
     'th-rs-wrp' : setClass == 3 ? 'th-gr-wrp' : 'th-rs-wrp'"
  >
    <div class="init">
      <p>
        {{ initialStrProps }}
      </p>
    </div>
    <div class="desc">
      <p>
        {{ initialDescStrProps }}
      </p>
    </div>
    <div v-if="checkSign" :class="setClass == 0 ? 'th-gf-wrp' : setClass == 1
    ? 'th-ls-wrp' : setClass == 2 ?
     'th-rs-wrp' : setClass == 3 ? 'th-gr-wrp' : 'th-rs-wrp'">
      <b-icon icon="check" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'roundLabelWithCheck',
  props: {
    initialStrProps: {
      type: String,
      default: 'GF',
    },
    initialDescStrProps: {
      type: String,
      default: 'General Front',
    },
    checkSign: {
      type: Boolean,
      default: true,
    },
    labelAlert: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    setClass() {
      return this.labelAlert;
    },
  },
};
</script>

<style lang="scss" scoped>
  .th-gf-wrp {
  display: flex;
  flex-direction: row;
  align-items: center;

    & > svg {
      color: $da-vinci;
    }

    & > .init {
      width: 30px;
      height: 30px;
      background-color: $bloody-transparent;
      @include border-radius(50%);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        @include font-source($openSans, 10px, $da-vinci, normal, 1.7, normal);
        text-transform: uppercase;
      }
    }

    & > .desc {
      @include space($type:margin, $direction:left, $amount:18px);
      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        text-transform: capitalize;
        @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
      }
    }

    & > .check {
      @include space($type:margin, $direction:left, $amount:3px);
      color: $da-vinci;
    }
  }
  .th-ls-wrp {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      color: $morgan;
    }

    & > .init {
      width: 30px;
      height: 30px;
      background-color: $sunny-transparent;
      @include border-radius(50%);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        @include font-source($openSans, 10px, $morgan, normal, 1.7, normal);
        text-transform: uppercase;
      }
    }

    & > .desc {
      @include space($type:margin, $direction:left, $amount:18px);
      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        text-transform: capitalize;
        @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
      }
    }

    & > .check {
      @include space($type:margin, $direction:left, $amount:3px);
      color: $morgan;
    }
  }
  .th-gr-wrp {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      color: $btn-primary-custom;
    }

    & > .init {
      width: 30px;
      height: 30px;
      background-color: $joe-green;
      @include border-radius(50%);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        @include font-source($openSans, 10px, $jessica-monique, normal, 1.7, normal);
        text-transform: uppercase;
      }
    }

    & > .desc {
      @include space($type:margin, $direction:left, $amount:18px);
      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        text-transform: capitalize;
        @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
      }
    }

    & > .check {
      @include space($type:margin, $direction:left, $amount:3px);
      color: $jessica-monique;
    }
  }
  .th-rs-wrp {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > svg {
      color: $btc-fancy;
    }

    & > .init {
      width: 30px;
      height: 30px;
      background-color: $btc-fancy;
      @include border-radius(50%);
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        @include font-source($openSans, 10px, $jessica-monique, normal, 1.7, normal);
        text-transform: uppercase;
      }
    }

    & > .desc {
      @include space($type:margin, $direction:left, $amount:18px);
      p {
        @include space($type:margin, $direction:bottom, $amount:0);
        text-transform: capitalize;
        @include font-source($openSans, 14px, rgba(30, 45, 62, 0.6), normal, 1.21, normal);
      }
    }

    & > .check {
      @include space($type:margin, $direction:left, $amount:3px);
      color: $jessica-monique;
    }
  }
</style>
